export const Navigation = (props) => {
  const {thanks} = props
  return (
    <>
    {!thanks &&
    <div className="header-top">
      <div className="auto-container-header">
        <div className="top-inner clearfix">
          <div className="phone-box pull-left"><a href="tel:3128476008">
            <i class="fas fa-phone"></i>
            <p>
              Call us for a FREE Estimate. Dial NOW{" "}
              (+1) (312) 847-6008 
            </p></a>
          </div>
          <div className="right-column pull-right clearfix">
            <div className="single-box">
              <i className="far fa-clock"></i>
              <p>Mon - Sun : 7AM-11PM</p>
            </div>
            </div>
          <div className="right-column pull-right clearfix">
            <div className="single-box">
              <ul className="social-links clearfix">
                <li> <a href="https://www.facebook.com/neilsgaragedoors"> <i className="fab fa-facebook-f"></i></a></li>
                <li><a href="https://www.instagram.com/neilsgaragedoors/"><i className="fab fa-instagram"></i></a></li>
                <li><a href="https://www.yelp.com/biz/neils-garage-doors-chicago-2"><i class="fa-brands fab fa-yelp"></i></a></li>{" "}
              </ul>
            </div>
          </div>
        </div>
      </div>  
    </div>}
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container-header">
        
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {<figure className="footer-logo">
            <a href="/">
              <img
                width="207"
                height="50"
                src="img/logos/headerLogo.png"
                className="headerLogo"
                alt=""
                loading="lazy"
              />{" "}
            </a>
          </figure>}
        </div>
        {!thanks &&
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Services
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
            {/* <li>
              <div className="btn-box-header">
                <i className="fa-solid fa-calcuForumr"></i>
                <a href="#contact" className="theme-btn-one">
                  <span className="btn-shape"></span>Get A quote
                </a>
              </div>
            </li>*/}
          </ul>
        </div>}
      </div>
    </nav>
    
    </>
  );
};
