import { useState } from 'react'
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const initialState = {
  name: '',
  email: '',
  phone: '',
  service: ''
}
export const Contact = (props) => {
  const navigate = useNavigate();
  const [{ name, email, phone, service}, setState] = useState(initialState)
  const [success, setSucces] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, phone, service)
    axios
      .get('https://hooks.zapier.com/hooks/catch/13827609/3yuvk4w/', {
        name, email, phone, service
      })
      .then((response) => {
          console.log(e.target)
          clearState()
          setSucces(true)
          navigate('/thank-you');
      });
  }
  return (
    <div id='contact'>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>Please fill out our form and we will reach out to you ASAP!</p>
              </div>
              
              {success ? <h2 id='success'>Thanks for contacting us! We will be in touch with you shortly.</h2> :
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-4 col-xs-12'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-4 col-xs-12'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='phone'
                        name='phone'
                        className='form-control'
                        placeholder='Phone'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-4 col-xs-12'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                <div className='form-group'>
                      <select 
                      name='service'
                      id='service'
                      className='form-control select-wrapper'
                      placeholder='Type of Service'
                      required
                      onChange={handleChange}
                      >
                        <option >Type Of Service</option>
                        <option value="carLockout">Car/House lockout</option>
                        <option value="carKeyMake">Car key make</option>
                        <option value="carKeyProgram">Car key program</option>
                        <option value="residentialFix">Residential lock change/fix</option>
                        <option value="commercialFix">Commercial lock change/fix</option>
                        <option value="other">Other</option>
                      </select>
                      <p className='help-block text-danger'></p>
                    </div>
                </div>
                <div id='success'></div>
                <button  type='submit' className="theme-btn-one btn-shape col-xs-12 col-md-4" style={{marginTop: '20px', float: 'right'}}>
                 <span>Send Message</span> 
                </button>
              </form>
              }
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
