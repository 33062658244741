import React from 'react';
function Check(props){
    return(
        <div className="flex">
            <i className="fas fa-check-circle"></i>
            <p>{props.description}</p>
        </div>
    )
}

export default Check