import React from 'react';
import Check from './Check';


function ServiceBanner() {

    return (
        <div className="firstBanner" id='services'>
            <div className="banner-title">
                <div className='section-title' style={{marginBottom: '10px'}}>
                    <h2>Our Services</h2>
                    <p style={{ color: '#242424' }}>We are pleased to serve residential and commercial clients across the city and suburbs. We offer comprehensive services, so you can rely on us for lock and key needs.</p>
                </div>
                <div className="banner-services">
                    <div className="banner-service banner-white">
                        <div className="principal_banner">
                            <i className="fas fa-car-side"></i>
                            <h2>Automotive</h2>
                            <p className="paragraph">you can trust us for quality work and unbeatable customer service</p>
                        </div>
                        <div className="checks">
                            <Check
                                description="UNLOCK CAR TRUNK"
                            ></Check>
                            <Check
                                description="CAR LOCK OUT"
                            ></Check>
                            <Check
                                description="KEY REPLACEMENT"
                            ></Check>
                            <Check
                                description="IGNITION REPAIR"
                            ></Check>
                            <Check
                                description="TRANSPONDER KEYS"
                            ></Check>
                            <Check
                                description="KEY FOBS"
                            ></Check>
                            <Check
                                description="BROKEN KEYS"
                            ></Check>
                        </div>
                    </div>
                    <div className="banner-service banner-grey">
                        <div className="principal_banner">
                            <i className="fas fa-warehouse"></i>
                            <h2>Garage Doors</h2>
                            <p className="paragraph">Your go-to professional garage door technicians in Chicago.</p>
                        </div>
                        <div className="checks">
                            <Check
                                description="SPRING REPAIR"
                            ></Check>
                            <Check
                                description="SPRING REPLACEMENT"
                            ></Check>
                            <Check
                                description="OPENER REPAIRS"
                            ></Check>
                            <Check
                                description="OPENER REPLACEMENT"
                            ></Check>
                            <Check
                                description="DOOR REPAIR"
                            ></Check>
                            <Check
                                description="DOOR REPLACEMENT"
                            ></Check>
                            <Check
                                description="NEW INSTALLATION"
                            ></Check>
                        </div>
                    </div>
                    <div className="banner-service banner-white">
                        <div className="principal_banner">
                            <i className="fas fa-home"></i>
                            <h2>Residential</h2>
                            <p className="paragraph">We offer prompt appointments and high-quality work.</p>
                        </div>
                        <div className="checks">
                            <Check
                                description="LOCKOUTS"
                            ></Check>
                            <Check
                                description="LOCK REPAIR"
                            ></Check>
                            <Check
                                description="LOCK REKEYING"
                            ></Check>
                            <Check
                                description="LOCK CHANGES"
                            ></Check>
                            <Check
                                description="LOCK UPGRADES"
                            ></Check>
                            <Check
                                description="KEY DUPLICATION"
                            ></Check>
                            <Check
                                description="MAILBOX LOCKS"
                            ></Check>
                        </div>
                    </div>
                    <div className="banner-service banner-grey">
                        <div className="principal_banner">
                            <i className="fas fa-store"></i>
                            <h2>Commercial</h2>
                            <p className="paragraph">We can answer your security needs or issues.</p>
                        </div>
                        <div className="checks">
                            <Check
                                description="UNLOCKING SERVICES"
                            ></Check>
                            <Check
                                description="LOCK REPLACEMENTS"
                            ></Check>
                            <Check
                                description="HIGH-SECURITY"
                            ></Check>
                            <Check
                                description="LOCK REPAIR"
                            ></Check>
                            <Check
                                description="LOCK PICKING"
                            ></Check>
                            <Check
                                description="MASTER REKEYING"
                            ></Check>
                            <Check
                                description="KEYLESS ENTRY"
                            ></Check>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceBanner;