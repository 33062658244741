import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Why } from "./components/why";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Footer } from "./components/footer";
import { Contact } from "./components/contact";
import { Form } from "./components/form";
import { Home } from "./components/map";
import { ServiceArea } from "./components/serviceArea";
import { Services } from "./components/services";
import BrandsSlider from './components/BrandsSlider'
import BannerService from "./components/BannerServices"

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" exact element={
            <>
              <Navigation />
              <Header data={landingPageData.Header} />
              <Form />
              <About data={landingPageData.About} />
              <BannerService />
              <BrandsSlider />
              <Testimonials data={landingPageData.Testimonials} />
              <Team data={landingPageData.Team} />
              <Home />
              <ServiceArea />
              <Gallery data={landingPageData.Gallery} />
              <Contact data={landingPageData.Contact} />
              <Footer data={landingPageData.Contact} />
            </>} />
          <Route path="/thank-you" element={
            <>
              <Navigation thanks={true} />
              
            <div style={{margin: '2em auto', textAlign:'center', maxWidth: '50%'}}>
              
              <i class="fa fa-check" style={{fontSize:'6em', color:'rgb(10,10,10)'}}></i>
              <h2 style={{marginTop: 'auto',}}>¡Thank You!</h2>
              <p>Thank you for reaching out to us! We appreciate you taking the time to submit your information. 
                Our team will review your message and respond as soon as possible. 
                If you require immediate assistance, please call our helpline at <a href="tel:3128476008">(312) 847-6008</a>.</p>
                </div>
           
                </>} />
        </Routes>
      </div>
    </Router>
  );
};
export default App;
