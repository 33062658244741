export const Header = (props) => {
  return (
    <>
      <header id='header'>
        <div className='intro'>
          <div className='overlay'>
            <div className='container'>
              <div className='col-md-8 intro-text'>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <div className="btn-box clearfix">
                  <div className="call-now">
                  <a href="tel:3128476008">
                    <div className="inner">
                      <i class="fas fa-mobile-alt"></i>
                      <div style={{ paddingLeft: '10px' }}>
                        <span> Book an Appointment Today!</span>
                        <h3>(+1) (312) 847-6008 </h3>
                      </div>
                    </div>
                    </a>
                  </div>
                  <a href="#about" target="_blank" rel="nofollow" className="theme-btn-one"><span className="btn-shape"></span><span>learn more</span></a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
